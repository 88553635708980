import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Modal, Button, Card, ListGroup } from 'react-bootstrap';

import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
// import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import ZooToD0001 from "../../images/portfolio/zootodo/zootodo_001.png"; 
import ZooToD0002 from "../../images/portfolio/zootodo/zootodo_001.gif"; 
import DublinAR0001 from "../../images/portfolio/dublinar/dublinar_001.png";
import PUMP001Image from "../../images/portfolio/pump/pump-001.png"; 

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-12 lg:py-12`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-8 xl:last:mr-0`;

const HeadingColumn = styled(Column)(props => [
  tw`w-full mb-4`,
  // props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center text-lg hover:no-underline`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const CardCustom = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs `;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded-xl `
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-primary-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;
const CardTitle = tw.h5`text-xl mt-4 font-bold`;


const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;
const handleContactClick =  (e) =>{
  document.getElementById('contact-link').click()
}


// const CardAction = tw(PrimaryButtonBase)`w-full mt-6`;

const CardModal = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="info" onClick={handleShow} tw="w-48 my-2 py-3 bg-primary-500 hover:bg-gray-800 w-full">
        More
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.card.company}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding:'unset'}}>
          <Card >
            {props.card.video ? 
            <StyledResponsiveVideoEmbed
            url={props.card.video}
            background="transparent"
            />
            : null
            }
            {props.card.images ? <img style={{margin:'auto'}} src={props.card.images} alt='' /> : null}            
            <Card.Body>
              <Card.Title>{props.card.title}</Card.Title>
              <Card.Text>
                {props.card.description}
                <br/><br/>
                {props.card.description2}
                <br/><br/>
                {props.card.description3}
              </Card.Text>
              {props.card.links ? Object.keys(props.card.links).map(item => {return<Button variant="outline-info" tw="w-24 mx-2 mt-4" href={props.card.links[item]}  target="_blank">{item}</Button>}) : null}

            </Card.Body>
            <Card.Header>Tech</Card.Header>
            <ListGroup variant="flush">
            {props.card.tech ? props.card.tech.map((item) => {return<ListGroup.Item>{item}</ListGroup.Item>}) : null}
            </ListGroup>            
          </Card>          
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ({
  subheading = "Some examples of the work we have done.",
  headingHtmlComponent = (
    <>
      <span tw="text-primary-500">Portfolio</span> 
    </>
  ),
  description = "Our portfolio of work spans across numerous industries and technologies. We have worked on building stand-alone, UI toolsets within existing applications to ground-up, bespoke organisation web applications. Our mindset is that monotony is dead and automation is key.",
  linkText = "Contact us to discuss Projects ",
  textOnLeft = false
}) => {
  const cards = [
    {
      imageSrc: PUMP001Image,
      company: "P.U.M.P",
      type: "Web Application",
      title: "Automated studio tools",
      durationText: "180 Day Campaign",
      locationText: "SSVFX",
      video: "//player.vimeo.com/video/572191453?title=0&portrait=0&byline=0&autoplay=0&responsive=1",
      description: "P.U.M.P is an acronym for Production Utility for Managing Projects. Its primary purpose is to automate the processing of large data typical of VFX studios.",
      description2: "This web application took data from the Shotgrid API on shots, scanned the server for relative directories for image sequences and then created movie files and other important sequence files based on the project's specificiation. It also published this newly created content back into Shotgrid for future use and reference. Similarly it is also used to process and package files, such as editorial movies and final image sequences, for sending completed work back to client.",
      description3: "Production management teams now have a tool to manage the large amounts of incoming and outgoing data sets. This web application was integrated into the Shotgrid Action Menu Items and is easily accessable via the relative Project pages.",

      links: {
        ssvfx: "//ssvfx.ie",
        shotgrid: "//shotgridsoftware.com",
      },
      tech: ["Django", "Python", "Thinkbox Deadline"]

    },
    {
      imageSrc:ZooToD0001,
      company: "Zoo todos",
      type: "Web Application",
      title: "Interactive itinerary",
      durationText: "30 Days ",
      locationText: "Private",
      video: null,
      images: [ZooToD0002],
      description: "This is a portfolio piece, proof of concept, interactive web application. It's purpose is to enhance the visitor’s experience at a zoo or wildlife park, by organising their day of activities into a fun and interactive to-do list. Essentially, providing a personalised itinerary and guide for their day out.",
      description2: "Each animal (or attraction) in this list can be clicked into and expanded to show further information; a photo of the animal, general information and fun facts. When the visitor has seen the animal, they can mark it as complete, by ticking the green icon to the right of the animal’s name. This animal will then be moved to the Done list and the visitor can continue to the next animal.",
      description3: "This proof of concept is applicable to any industry, as an itinerary or things todo web app.",
      links: {
        Signup: "//zoo-todo.herokuapp.com/",
        Sample: "//zoo-todo.herokuapp.com/places/example"
      },
      tech: ["Django", "React", "Python", "PostgreSQL"]
    },
    {
      imageSrc:DublinAR0001,
      company: "DublinAR",
      type: "Web Application",
      title: "City Tourist Guide",
      durationText: "30 Days ",
      locationText: "Private",
      video: null,
      description: "This is a personal, proof of concept, web application. It's purpose is to provide the visitor’s of Dublin City, with an organised collection of places to see and things to do.",
      description2: "The DublinAR web app displays a simple, interactive collection of Place Categories, like Activities, Fun, History & Culture and Sightseeing. Within these collections, the visitor can further refine Places by Type (Art/Religious/Ancient) and Opions (free/parking/wifi) ",
      links: {
        DublinAR: "//dublinar.herokuapp.com",
      },
      tech: ["Django", "React", "Python", "PostgreSQL"]
    }    
  ];
  return (
    <Container id='portfolio'>
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <Subheading>{subheading}</Subheading>
              <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
              <HeadingDescription>{description}</HeadingDescription>
              <PrimaryLink onClick={(e)=>handleContactClick(e)}>
                {linkText} <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <CardCustom>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardCompany>{card.company}</CardCompany>
                    <CardType>{card.type}</CardType>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  <CardModal card={card} />
                </CardText>
              </CardCustom>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
