import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";


import SupportIconImage from "../../images/support-icon.svg";
import InternetIconImage from "../../images/internet-icon.svg";
import ChoiceIconImage from "../../images/choice-icon.svg";
import ProgrammingIconImage from "../../images/programming-icon.svg";
import IdeaIconImage from "../../images/idea-icon.svg";
import ReferIconImage from "../../images/broken-link-icon.svg";
import ChatIcon from "../../images/chat-icon.svg";

// import ShieldIconImage from "../../images/shield-icon.svg";
// import WebsiteIconImage from "../../images/webiste-icon.svg";
// import GlobeIconImage from "../../images/globe-icon.svg";
// import DevelopmentIconImage from "../../images/development-icon.svg";
// import CustomizeIconImage from "../../images/customize-icon.svg";
// import FastIconImage from "../../images/fast-icon.svg";
// import ReliableIconImage from "../../images/reliable-icon.svg";
// import SimpleIconImage from "../../images/simple-icon.svg";

import WebsiteImage from "../../images/services/card/bg/webiste-bg.jpg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-3 flex`}
`;

const Card = styled.div`
  ${tw`transition duration-500 ease-in-out hover:bg-gradient-to-t from-gray-100 via-transparent to-gray-100 transform hover:-translate-y-1 hover:scale-110	flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-gray-900 rounded-lg mt-8 hover:border-solid hover:shadow-xl`}
  .imageContainer {
    ${tw` border-2 border-gray-900 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

export default ({
  animation = false,
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    { bgImage: WebsiteImage, imageSrc: InternetIconImage, title: "WebSite", description: "Do you need to get your work or business online fast? Do you want your own slick website and URL? We can help!" },
    { imageSrc: ChoiceIconImage, title: "Application", description: "Design, build and launch web applications to meet your requirements. From simple forms to complex tools." },
    { imageSrc: ProgrammingIconImage, title: "Development", description: "Provide software developement services to build & integrate tools into your webiste / organisation. Using existing platforms or tailored solutions." },
    {
      imageSrc: ChatIcon,
      title: "Free 2hr Consultation",
      description: "Have a virtual or physical meet-up and talk through your project requirements to plan how to start and proceed. Exciting!"
    },
    { imageSrc: IdeaIconImage, title: "New Tech", description: "We love new & emerging technologies. If there is new tech that you want to trial in your organisation, but don't know how? We can help!"},
    { imageSrc: SupportIconImage, title: "Tailored Support", description: "Offer support at every step of way during the development of your project. We also offer support packages after project completion."},
    { imageSrc: ReferIconImage, title: "Referal", description: "If we don't offer the services you require, we can direct and connect you to our far-reaching database of connections."},

  ];

  return (
    
    <Container id="services">
      <ThreeColumnContainer>
        <Heading>Our <span tw="text-primary-500">Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card >
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
