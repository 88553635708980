import React, { useState, useEffect } from "react";
import tw, {theme} from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Typist from 'react-typist';

import { HashLink } from 'react-router-hash-link';
import Header, { NavLink, NavLinks, PrimaryLink, SecondaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
// import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import { ReactComponent as PhoneCallIcon } from "feather-icons/dist/icons/phone-call.svg";
import { ReactComponent as MailIcon } from "feather-icons/dist/icons/mail.svg";

// import CellImage from  "https://appseed-147a1.kxcdn.com/static/assets/img/web-app-generator-appseed.png"
import MainImage from "../../images/top/main-image-001.png";

const StyledHeader = styled(Header)`
  ${tw`pt-4 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 sm:pb-24 bg-center bg-cover bg-gray-900 rounded-b-xl `}
`;

// const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;
const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-12 sm:pb-48 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex sm:pt-32 flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:pt-32 sm:w-5/6 lg:w-1/2 mt-4 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2 w-128`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`
  text-lg my-6 p-4 sm:text-base sm:mt-16 sm:px-8 sm:py-4 
  bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-700 
  hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

// const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
//   padding-bottom: 56.25% !important;
//   padding-top: 0px !important;
//   ${tw`rounded`}
//   iframe {
//     ${tw`rounded bg-black shadow-xl`}
//   }
// `;

export default () => {
  const [renderMessage, setRenderMessage] = useState(true)

  useEffect(() => {
    // document.title = `You clicked ${count} times`;
    console.log("renderMessage: " + renderMessage);
    setRenderMessage(true);
  }, [renderMessage]);

  const navLinks = [
    <NavLinks key={1}>
      <NavLink id="backstory" as={HashLink} to="#about" smooth >
        About
      </NavLink>
      <NavLink id="services-link"  as={HashLink} to="#services" smooth>
        Services
      </NavLink>
      <NavLink as={HashLink} to="#tech" smooth>
        Tech
      </NavLink>
      <NavLink id="portfolio-link" as={HashLink} to="#portfolio" smooth>
        Portfolio
      </NavLink>
    </NavLinks>,
    <NavLinks key={2} >
      <PrimaryLink id="contact-link" as={HashLink} to="#contact-us" smooth>
        <MailIcon />
      </PrimaryLink>
      <SecondaryLink href="tel:+3537207129">
        <PhoneCallIcon />
      </SecondaryLink>      
    </NavLinks>
  ];
  const handleStoryClick =  () =>{
    document.getElementById('backstory').click()
  }
  return (
    <Container 
      css={[
      css({
        // Grab values from your config with the theme import
        background: `linear-gradient(
          ${theme`colors.gray.800`} 25%,
          ${theme`colors.gray.700`} 75%
          
        )`
      })
    ]}>
      {/* <OpacityOverlay /> */}
      <StyledHeader links={navLinks} />
      <HeroContainer>
        <TwoColumn>
          <LeftColumn>
            <Notification>Welcome to an exciting new development agency.</Notification>
            <Heading>
              <span>There is </span>
              <br />
              <SlantedBackground>  
                {renderMessage ?
                <Typist
                cursor={{blink:true}}
                startDelay={1000}
                
                onTypingDone={() => setRenderMessage(false)}
              >
                <span> an API </span>
                <Typist.Backspace count={11} delay={800} />
                <span> automation </span>
                <Typist.Backspace count={11} delay={800} />                
                <span> a solution </span>
                <Typist.Backspace count={11} delay={800} />
                <Typist.Delay ms={750} />
                <span> a WebAppDev </span>
                <Typist.Delay ms={3500} />
                <Typist.Backspace count={11} delay={800} />

              </Typist>
              : null}
              </SlantedBackground>
              <br />
              <span >for that!</span>
            </Heading>
            <PrimaryAction onClick={()=>handleStoryClick()}>Read the backstory</PrimaryAction>
          </LeftColumn>
          <RightColumn>
            {/* <StyledResponsiveVideoEmbed
              url= "https://appseed-147a1.kxcdn.com/static/assets/img/web-app-generator-appseed.png"//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            /> */}
            <img tw="object-scale-down h-48 sm:h-128 w-full" alt="" src={MainImage}/>
            {/* <CellImage /> */}
            <a tw="text-gray-600 hover:text-blue-600 float-right"  href='https://pngtree.com/so/the-internet'>credit</a>

          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
