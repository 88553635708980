import React from "react";
import tw from "twin.macro";

/* framer-motion and useInView here are used to animate the sections in when we reach them in the viewport
 */
import { motion } from "framer-motion";
import useInView from "@owaiswiz/use-in-view";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
function AnimationReveal({ disabled, children }) {
  if (disabled) {
    return <>{children}</>;
  }

  if (!Array.isArray(children)) children = [children];

  const directionsX = ["left"];
  const directionsY = ["botton"];

  const childrenWithAnimation = children.map((child, i) => {
      if (!child.props.animation){
        return(child)}else{
      if(child.props.direction === "slideX"){
          return(
            <AnimatedSlideXComponent key={i} direction={directionsX[i % directionsX.length]}>
              {child}
            </AnimatedSlideXComponent>
          );
        }else if(child.props.direction === "slideY") {
          return(
            <AnimatedSlideYComponent key={i} direction={directionsY[i % directionsY.length]}>
              {child}
            </AnimatedSlideYComponent>
          );
        }else{
          return(child)
        }
    }
  });
  return <>{childrenWithAnimation}</>;
}

function AnimatedSlideYComponent({ direction = "bottom", offset = 15, children }) {
  const [ref, inView] = useInView(15);

  const y = { target: "0%" };

  if (direction === "bottom") y.initial = "-50%";
  else y.initial = "50%";

  return (
    <motion.section
      initial={{ y: y.initial }}
      animate={{ 
        y: inView && y.target,
        transitionEnd:{
          y: inView && 0
        }
      }}
      transition={{  type: "spring", duration: 0.8, damping: 19 }}
      ref={ref}
    >
      {children}
    </motion.section>
  );
}

function AnimatedSlideXComponent({ direction = "left", offset = 30, children }) {
  const [ref, inView] = useInView(50);

  const x = { target: "0%" };

  if (direction === "left") x.initial = "-150%";
  else x.initial = "150%";

  return (
    <motion.section
      initial={{ x: x.initial }}
      animate={{ 
        x: inView && x.target,
        transitionEnd:{
          x: inView && 0
        }
      }}
      transition={{ type: "spring", damping: 19, ease: "easeOut" }}
      ref={ref}
    >
      {children}
    </motion.section>
  );
}


export default props => (
  <StyledDiv className="App">
    <AnimationReveal {...props} />
  </StyledDiv>
);
