import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase, } from "components/misc/Buttons.js";
// SecondaryButton as  SecondaryButtonBase
import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
// const SpaceContainer = tw.div`group-hover:hidden`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-24 md:py-24 `;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight `;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

// const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
// const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
// const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
// const Key = tw.div`font-medium text-primary-700`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-2`;
// const SecondaryButton  = tw(SecondaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);
const handleContactClick =  () =>{

  document.getElementById('contact-link').click()
}
export default ({
  subheading = "Mission Statement:",
  heading = (
    <> 
      {/* cursor: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/happy.png"), auto; */}
      Create and build <span tw="text-primary-500"> slick </span> web applications <wbr /> of  the <span id="future" tw="text-primary-500">future</span>.
    </>
  ),
  description = "WebAppDev is a software development provider that develops slick and nifty, web applications, whose principles are driven in strong and smart user interaction and user experience (UI/UX).",
  description2= "Web applications are used extensively by businesses and organisations to assist, improve and automate tasks undertaken in the daily operations of the company. These can range from simple web forms for data collection to complex organisation, automation tools.",
  description3= "Building such applications is the foundation of WebAppDev, as well as providing a wide range of other web, application and development services. Get in touch today to discuss your project and we can work together to develop a solution.",
  primaryButtonText = "Contact",
  primaryButtonUrl = "#",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false
}) => {

  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "Years",
      value: "0"
    },
    {
      key: "Projects",
      value: "0"
    },
    {
      key: "Awards",
      value: "0"
    }
  ];

  if (!statistics) statistics = defaultStatistics;

  // <div className="group" id="about">
  //   <div tw="group-hover:bg-black">Child 1</div>
  return (
    <Container id="about">    
    <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
      <ImageColumn css={imageContainerCss}>
        {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="" />}
        {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
      </ImageColumn>
      <TextColumn textOnLeft={textOnLeft}>
        <TextContent>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
          <Description>{description2}</Description>
          <Description>{description3}</Description>
          {/* <Statistics>
            {statistics.map((statistic, index) => (
              <Statistic key={index}>
                <Value>{statistic.value}</Value>
                <Key>{statistic.key}</Key>
              </Statistic>
            ))}

          </Statistics> */}
          {/* <SecondaryButton onClick={(e)=>console.log(e) }>
            About
          </SecondaryButton>           */}
          <PrimaryButton onClick={()=>handleContactClick()}>
            {primaryButtonText}
          </PrimaryButton>
        </TextContent>
      </TextColumn>
    </TwoColumn>
    </Container>    
  );
};
